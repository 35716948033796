import { observer } from "mobx-react";
import React from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { RouteComponentProps } from "react-router";
import { Button, Header, Icon } from 'semantic-ui-react';
import IFrameLoader from '../components/IFrameLoader';
import { RootStoreType, useQuery } from '../models';
import { GridPosModelType } from "../models/GridPosModel";
import { useInject } from "../utils/useStore";


const ResponsiveGridLayout = WidthProvider(Responsive);

interface Params {
    siteName: string;
    uid: string;
}
interface DashboardProps extends RouteComponentProps<Params> {
}

const resultSelector = `id
uid
title
panels {
  id
  embedUrl
  gridPos{
      x
      y
      w
      h
  }
}`;

const Dashboard = ({ match, history }: DashboardProps) => {

    const { uid, siteName } = match.params;

    const { store, error, loading, data } = useQuery((store: RootStoreType) =>
        store.queryDashboardByUid({siteName, uid}, resultSelector)
    );

    const access_token = useInject((store) => store.auth.user?.access_token)

    const rowHeight = 30;
    const panels = data && data.dashboardByUid.panels ? data.dashboardByUid.panels : [];

    const gridChildren = React.useMemo(() => {
        return panels.map((panel, index) => {
            const gridPos: GridPosModelType = panel.gridPos;
            const h = gridPos.h! * rowHeight;
            return (
                <div key={index.toString()}>
                    <IFrameLoader id={index} url={panel.embedUrl} height={h.toString()} />
                </div>
            )
        });
    }, [panels.length]);

    const layouts = panels.map((panel, index) => {
        const gridPos: GridPosModelType = panel.gridPos;
        return {
            i: index.toString(),
            x: gridPos.x!,
            y: gridPos.y!,
            w: gridPos.w!,
            h: gridPos.h!,
            static: true,
        } as Layout;
    });
    const layoutss = {
        lg: layouts,
        md: layouts,
    };

    const gridd = (
        <ResponsiveGridLayout className="layout" layouts={layoutss} rowHeight={rowHeight} breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }} cols={{ lg: 18, md: 18, sm: 18, xs: 10, xxs: 6 }}>
            {gridChildren}
        </ResponsiveGridLayout>
    );

    const backToDashboard = () => history.goBack();

    const dashboardName = data ? data.dashboardByUid.title!.toLowerCase().replaceAll(' ', '-') : ''

    return (
        <div>
            <Button inverted icon={true} onClick={backToDashboard}>
                <Icon name="chevron left" />
                Back to dashboards
            </Button>
            <Header inverted>{data?.dashboardByUid.title}</Header>
            {gridd}
        </div>
    )
};

export default observer(Dashboard);
