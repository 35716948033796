import React from 'react';
import { Route, RouteProps } from 'react-router';
import { useInject } from '../utils/useStore';

interface PrivateRouteProps extends RouteProps {
}

const DISABLE_AUTH = true;

const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {

    const { isAuthenticated, signinRedirect } = useInject((store) => ({
        isAuthenticated: store.auth.isAuthenticated,
        signinRedirect: store.auth.signinRedirect
    }));

    const renderFn = (Component: React.ComponentType<any> | undefined) => (props: any) => {
        if (!!Component && DISABLE_AUTH) {
            return <Component {...props} />;
        }
        if (!!Component && isAuthenticated()) {
            return <Component {...props} />;
        } else {
            signinRedirect()
            return <div>loading...</div>
        }
    }
    return <Route {...rest} render={renderFn(component)} />
};

export default PrivateRoute;
