import { useContext } from "react";
import { RootStoreType, StoreContext } from "../models";

export const useStore = () => useContext(StoreContext);

export type MapStoreFunc<T> = (store: RootStoreType) => T

export const useInject = <T>(mapStore: MapStoreFunc<T>) => {
    const store = useStore();
    return mapStore(store)
};
