import { GraphQLClient } from "graphql-request";
import { getEnv, types } from "mobx-state-tree";
import { User, UserManager, WebStorageStateStore } from "oidc-client";
import IDENTITY_CONFIG, { authority, client_id } from "../utils/identityConfig";

const userManager = new UserManager({
    ...IDENTITY_CONFIG,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
});
userManager.events.addUserLoaded((user) => {
    console.log('user loaded ' + user);
});

const AuthStore = types.model("AuthStore", {
    user: types.frozen<User | null>()
})
.views((self) => ({
    get username() {
        return self.user ? self.user.profile.preferred_username : '';
    }
}))
.actions((self) => ({
    setUser(user: User) {
        self.user = user;
        // update mst-gql http client bearer token
        (getEnv(self).gqlHttpClient as GraphQLClient).setHeaders({authorization: `Bearer ${user.access_token}`})
    },
    signinRedirect() {
        localStorage.setItem("redirectUri", window.location.pathname);
        userManager.signinRedirect();
    },
}))
.actions((self) => ({
    isAuthenticated() {
        const key = `oidc.user:${authority}:${client_id}`;
        const json = sessionStorage.getItem(key);
        if (json) {
            const user = User.fromStorageString(json);
            self.setUser(user);
            return (!!user && !!user.id_token);
        }
        return false;
    },
    signinRedirectCallback() {
        userManager.signinRedirectCallback()
            .then((user) => {
                self.setUser(user);
                const redirectUri = localStorage.getItem("redirectUri");
                if (redirectUri) {
                    window.location.replace(redirectUri);
                } else {
                    window.location.replace('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    signinSilent() {
        userManager.signinSilent()
            .then((user) => self.setUser(user))
            .catch((error) => {
                console.log('An error occured during signin silent', error)
                self.signinRedirect();
            });
    },
    signinSilentCallback() {
        userManager.signinSilentCallback()
            .then((user) => {
                if (user) {
                    self.setUser(user)
                } else {
                    console.log('for some reason sinin silent callback user is undefined')
                }
            })
            .catch((error) => {
                console.error('An error occured during signin silent callback', error)
            });
    },
    logout() {
        if (self.user) {
            userManager.signoutRedirect({
                id_token_hint: self.user.id_token
            });
            userManager.clearStaleState();
        }
    }
}))
.actions((self) => ({
    afterCreate() {
        userManager.events.addUserLoaded((user) => {
            console.log('user loaded');
            self.setUser(user);
        });
        userManager.events.addSilentRenewError((error) => {
            console.error('silent renew error', error);
        });
        userManager.events.addAccessTokenExpired(() => {
            console.log('token expired');
            self.signinSilent();
        });
        userManager.getUser()
            .then((user) => {
                if (user) {
                    self.setUser(user);
                }
            })
            .catch((error) => console.error(error));
    },
}))

export const createAuthStore = () => {
    return AuthStore.create();
}

export default AuthStore;
