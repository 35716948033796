import { useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Placeholder } from "semantic-ui-react";


interface IFrameLoaderProps {
    height: string;
    id: number;
    url: string;
}

const IFrameLoader = ({ height, id, url }: IFrameLoaderProps) => {

    const [isLoaded, setLoaded] = useState(false);
    const [isLoadedCompletely, setLoadedCompletely] = useState(false);
    const iframeDoc = useRef(null);

    useEffect(() => {
        if (iframeDoc.current) {
            // @ts-ignore
            //let doc = iframeDoc.current!.contentDocument || iframeDoc.current!.contentWindow.document;
            //console.log('doc is ' + doc);
            setTimeout(() => {
                setLoadedCompletely(true);
            }, 1000);
        }
    }, [isLoaded]);

    const _height = isLoadedCompletely ? height : "1";

    const elemId = 'elem-' + id;

    const loader = isLoadedCompletely ? null : (
        <Placeholder inverted fluid >
            <Placeholder.Image style={{height: `${height}px`}} />
        </Placeholder>
    );

    const iframeStyle = {
        display: isLoadedCompletely ? 'block' : 'none'
    };

    const content =  (
        <div id={elemId} style={{minHeight: `${height}`}}>
            <LazyLoadComponent threshold={100} afterLoad={() => setLoaded(true)}>
                <iframe title={elemId} loading="lazy" ref={iframeDoc} src={url} width="100%" height={_height} frameBorder="0" marginHeight={0} marginWidth={0} style={iframeStyle}></iframe>
            </LazyLoadComponent>
            {loader}
        </div>
    );

    return content;
    /* return (
        <>
            <div id={elemId} style={{minHeight: "400"}}>
                <Loader active={!isLoadedCompletely} />
                <iframe ref={iframeDoc} src={url} width="100%" height={height} frameBorder="0" marginHeight={0} marginWidth={0} onLoad={() => setLoaded(true)}></iframe>
            </div>
        </>
    ); */
}

export default IFrameLoader;
