import { observer } from 'mobx-react';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import SiteMenu from './SiteMenu';

interface Params {

}

interface LeftMenuProps extends RouteComponentProps<Params> {

}

const LeftMenu = ({ location }: LeftMenuProps) => {

    const activeItem = location.pathname;

    return (
        <Menu inverted fluid size="large" pointing vertical attached style={{height: '100%', backgroundColor: '#325035'}}>
            <Menu.Item>
                <img src='/verderon-logo.png' alt="verderon logo"/>
            </Menu.Item>
            <Menu.Item
                as={Link}
                to="/"
                name='home'
                icon="home"
                active={activeItem === '/'}
            />
            <Menu.Item>
                <Icon name="factory" />
                Sites
                <SiteMenu />
            </Menu.Item>
        </Menu>
    );
}

export default withRouter(observer(LeftMenu));

{/* <Menu.Item
as={Link}
to="/dashboards"
name='dashboards'
icon="chart bar"
active={activeItem === 'dashboard'}
/>
<Menu.Item
as={Link}
to="/models"
name='My Models'
icon="folder"
active={activeItem === 'My Models'}
/> */}
