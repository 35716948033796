import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useInject } from '../utils/useStore';

const SilentRenew = () => {
    const signinSilentCallback = useInject((store) => store.auth.signinSilentCallback)
    useEffect(() => {
        signinSilentCallback();
    });
    return <div>silent renew</div>
};

export default observer(SilentRenew);
