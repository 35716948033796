/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ModelBase
 * auto generated base class for the model ModelModel.
 */
export const ModelModelBase = ModelBase
  .named('Model')
  .props({
    __typename: types.optional(types.literal("Model"), "Model"),
    name: types.union(types.undefined, types.string),
    flowsheet: types.union(types.undefined, types.string),
    description: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ModelModelSelector extends QueryBuilder {
  get name() { return this.__attr(`name`) }
  get flowsheet() { return this.__attr(`flowsheet`) }
  get description() { return this.__attr(`description`) }
}
export function selectFromModel() {
  return new ModelModelSelector()
}

export const modelModelPrimitives = selectFromModel().name.flowsheet.description
