import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useInject } from '../utils/useStore';

const Logout = () => {
    const logout = useInject((store) => store.auth.logout);
    useEffect(() => {
        logout();
    });
    return <div>logging out..</div>
};

export default observer(Logout);
