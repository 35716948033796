import React from 'react';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import SiteMenu from '../components/SiteMenu';
import DefaultLayout from '../layouts/DefaultLayout';

const Home = () => {
    return (
        <DefaultLayout>
            <Grid columns={1}>
                <Grid.Column>
                    <Segment inverted>
                        <h1>Execute your Carbon Roadmap in Real Time</h1>
                        <Menu inverted fluid size="large" pointing vertical attached style={{ backgroundColor: '#325035' }}>
                            <Menu.Item>
                                Available sites
                                <SiteMenu />
                            </Menu.Item>
                        </Menu>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment inverted>
                        <p>Read more from <a href="https://verderon.io/" target={"_blank"} rel='noreferrer'>here.</a></p>
                    </Segment>
                </Grid.Column>
            </Grid>
        </DefaultLayout>
    )
};

export default Home;