import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { RootStoreType, useQuery } from '../models';

interface MyModelsProps {
    active: boolean;
    siteName: string;
}

const resultSelector = `name
models{
  name
}`;

const MyModels = ({ siteName }: MyModelsProps) => {

    const { store, error, loading, data } = useQuery((store: RootStoreType) =>
        store.querySites({}, resultSelector)
    );

    const siteList = data ? data.sites.filter((sitee) => sitee.name === siteName).map((sitee) => {
        const { models } = sitee;
        return models ? models.map(model => (
            <List.Item key={model.name}>
                <List.Icon name='chart bar' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as={Link} to={`/sites/${siteName}/models/${model.name}`}>{model.name}</List.Header>
                    <List.Description as={Link} to={`/sites/${siteName}/models/${model.name}`}>{model.name}</List.Description>
                </List.Content>
            </List.Item>
        )) : [];
    }) : [];

    return (
        <List divided relaxed inverted>
            {siteList}
        </List>
    )
}

export default observer(MyModels);
