import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { RootStoreType, useQuery } from '../models';

interface SiteMenuProps {
    activeItem?: string;
}

const SiteMenu = ({ activeItem }: SiteMenuProps) => {
    const { store, error, loading, data } = useQuery((store: RootStoreType) =>
        store.querySites()
    );

    const sitemenus = data ? data.sites.map((site, index) => {
        const siteLink = `/sites/${site.name}`;
        const active = activeItem ? activeItem.indexOf(siteLink) > -1 : false;
        return (
            <Menu.Item
                key={site.name}
                as={Link}
                to={siteLink}
                name={site.name}
                active={active}
            >
                {site.name}
            </Menu.Item>
        );
    }) : [];

    return (
        <Menu.Menu>
            {sitemenus}
        </Menu.Menu>
    )
};

export default observer(SiteMenu);