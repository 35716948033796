/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GridPosModel, GridPosModelType } from "./GridPosModel"
import { GridPosModelSelector } from "./GridPosModel.base"
import { RootStoreType } from "./index"


/**
 * PanelBase
 * auto generated base class for the model PanelModel.
 */
export const PanelModelBase = ModelBase
  .named('Panel')
  .props({
    __typename: types.optional(types.literal("Panel"), "Panel"),
    id: types.union(types.undefined, types.integer),
    gridPos: types.union(types.undefined, types.late((): any => GridPosModel)),
    embedUrl: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PanelModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get embedUrl() { return this.__attr(`embedUrl`) }
  gridPos(builder?: string | GridPosModelSelector | ((selector: GridPosModelSelector) => GridPosModelSelector)) { return this.__child(`gridPos`, GridPosModelSelector, builder) }
}
export function selectFromPanel() {
  return new PanelModelSelector()
}

export const panelModelPrimitives = selectFromPanel().embedUrl
