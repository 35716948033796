import 'fomantic-ui-less/semantic.less'
import './App.css';
import React from "react";
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import CallbackPage from './pages/callback';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import Logout from './pages/logout';
import LogoutCallback from './pages/logoutcallback';
import silentrenew from './pages/silentrenew';
import Site from './pages/site';

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export const App = () => {
  return (
    <Router>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/dashboard/:uid" component={Dashboard} />
          <PrivateRoute path="/sites/:name" component={Site} />
          <PrivateRoute path="/logout" component={Logout} />
          <Route path="/logoutcallback" component={LogoutCallback} />
          <Route path="/callback" component={CallbackPage} />
          <Route exact={true} path="/silentrenew" component={silentrenew} />
        </Switch>
    </Router>
  );
}
