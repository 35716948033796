/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PanelModel, PanelModelType } from "./PanelModel"
import { PanelModelSelector } from "./PanelModel.base"
import { RootStoreType } from "./index"


/**
 * DashboardBase
 * auto generated base class for the model DashboardModel.
 */
export const DashboardModelBase = ModelBase
  .named('Dashboard')
  .props({
    __typename: types.optional(types.literal("Dashboard"), "Dashboard"),
    id: types.union(types.undefined, types.integer),
    uid: types.union(types.undefined, types.string),
    title: types.union(types.undefined, types.string),
    panels: types.union(types.undefined, types.null, types.array(types.late((): any => PanelModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DashboardModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get uid() { return this.__attr(`uid`) }
  get title() { return this.__attr(`title`) }
  panels(builder?: string | PanelModelSelector | ((selector: PanelModelSelector) => PanelModelSelector)) { return this.__child(`panels`, PanelModelSelector, builder) }
}
export function selectFromDashboard() {
  return new DashboardModelSelector()
}

export const dashboardModelPrimitives = selectFromDashboard().uid.title
