import { observer } from 'mobx-react';
import React from 'react';
import { Image } from 'semantic-ui-react';
import md5 from '../utils/md5';
import { useInject } from '../utils/useStore';

const UserBadge = () => {
    const username = useInject((store) => store.auth.username);
    console.log(username);
    const gravatar = getGravatarURL(username!);
    return (
        <div>
            <Image src={gravatar} avatar={true} />
            <span>{username}</span>
        </div>
    )
};

function getGravatarURL(email: string) {
    // Trim leading and trailing whitespace from
    // an email address and force all characters
    // to lower case
    const address = email.trim().toLowerCase();

    // Create an MD5 hash of the final string
    const hash = md5(address);

    // Grab the actual image URL
    return `https://www.gravatar.com/avatar/${hash}?d=retro`;
}

export default observer(UserBadge);