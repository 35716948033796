/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { DashboardModel, DashboardModelType } from "./DashboardModel"
import { dashboardModelPrimitives, DashboardModelSelector } from "./DashboardModel.base"
import { GridPosModel, GridPosModelType } from "./GridPosModel"
import { gridPosModelPrimitives, GridPosModelSelector } from "./GridPosModel.base"
import { ModelModel, ModelModelType } from "./ModelModel"
import { modelModelPrimitives, ModelModelSelector } from "./ModelModel.base"
import { PanelModel, PanelModelType } from "./PanelModel"
import { panelModelPrimitives, PanelModelSelector } from "./PanelModel.base"
import { SiteModel, SiteModelType } from "./SiteModel"
import { siteModelPrimitives, SiteModelSelector } from "./SiteModel.base"



/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {

}


/**
* Enums for the names of base graphql actions
*/
export enum RootStoreBaseQueries {
querySites="querySites",
queryDashboardsBySite="queryDashboardsBySite",
queryDashboardByUid="queryDashboardByUid"
}


/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = withTypedRefs<Refs>()(MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['Dashboard', () => DashboardModel], ['GridPos', () => GridPosModel], ['Model', () => ModelModel], ['Panel', () => PanelModel], ['Site', () => SiteModel]], [], "js"))
  .props({

  })
  .actions(self => ({
    querySites(variables?: {  }, resultSelector: string | ((qb: SiteModelSelector) => SiteModelSelector) = siteModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ sites: SiteModelType[]}>(`query sites { sites {
        ${typeof resultSelector === "function" ? resultSelector(new SiteModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDashboardsBySite(variables: { siteName: string }, resultSelector: string | ((qb: DashboardModelSelector) => DashboardModelSelector) = dashboardModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dashboardsBySite: DashboardModelType[]}>(`query dashboardsBySite($siteName: String!) { dashboardsBySite(siteName: $siteName) {
        ${typeof resultSelector === "function" ? resultSelector(new DashboardModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryDashboardByUid(variables: { siteName: string, uid: string }, resultSelector: string | ((qb: DashboardModelSelector) => DashboardModelSelector) = dashboardModelPrimitives.toString(), options: QueryOptions = {}) {
      return self.query<{ dashboardByUid: DashboardModelType}>(`query dashboardByUid($siteName: String!, $uid: String!) { dashboardByUid(siteName: $siteName, uid: $uid) {
        ${typeof resultSelector === "function" ? resultSelector(new DashboardModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
  })))
