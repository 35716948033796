import React, { CSSProperties, useState } from 'react';
import { Dropdown, Header, Menu } from 'semantic-ui-react';
import LeftMenu from '../components/LeftMenu';
import UserBadge from '../components/UserBadge';
import { useInject } from '../utils/useStore';

const containerStyles = {
    display: 'flex'
};

const leftStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '250px'
};

const contentStyles: CSSProperties = {
    width: '100%',
    overflowY: "scroll"
};

const DefaultLayout = ({children}: any) => {

    const logout = useInject((store) => store.auth.logout);

    const [activeItem, setActiveItem] = useState('home');

    const handleItemClick = () => {
        setActiveItem('home');
    };

    const alerts = false ? (
        <Menu.Item
            position="right"
            name='alerts'
            active={activeItem === 'alerts'}
            onClick={handleItemClick}
        />
    ) : null;

    const signOut = () => logout();

    const possibleMenu = false ? (
        <Menu fluid secondary>
            <Menu.Item>
                <Header inverted size="huge">
                    Verderon LCA Dashboards
                </Header>
            </Menu.Item>
            {alerts}
        </Menu>
    ) : <div style={{paddingTop: '20px'}}></div>;

    return (
        <div style={containerStyles}>
            <div className="leftStyles" >
                <div style={{flexGrow: 1}}>
                    <LeftMenu />
                </div>
                <div>
                    <Menu inverted vertical fluid attached style={{ backgroundColor: '#325035' }}>
                        <Dropdown
                            fluid={true}
                            floating={true}
                            item={true}
                            direction={'left'}
                            icon=''
                            trigger={<UserBadge />}
                            options={[
                                {
                                    key: 'logout',
                                    text: 'Sign out',
                                    onClick: signOut
                                }
                            ]}
                        />
                    </Menu>
                </div>
            </div>
            <div style={contentStyles}>
                {possibleMenu}
                <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default DefaultLayout;