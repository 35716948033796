import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { useQuery } from '../models';
import { DashboardModelType } from "../models/DashboardModel";
import IFrameLoader from "./IFrameLoader";

interface DashboardsProps {
    siteName: string;
}

const resultSelector = `id
uid
title
panels {
  id
  embedUrl
  gridPos{
      x
      y
      w
      h
  }
}`;

const hasPanel = (dashboardsBySite: DashboardModelType[]): boolean => {
    if (dashboardsBySite.length > 0) {
        const db = dashboardsBySite[0];
        if (db.panels && db.panels.length > 0) {
            return true;
        }
    }
    return false;
}

const Dashboards = ({ siteName }: DashboardsProps) => {

    // see this github issue for the reason of this usage
    // https://github.com/mobxjs/mst-gql/issues/162#issuecomment-703157316
    const { setQuery, loading, data, error } = useQuery<{dashboardsBySite: DashboardModelType[]} |undefined>();
    useEffect(() => {
        setQuery((store) => store.queryDashboardsBySite({siteName}, resultSelector))
    }, [siteName]);

    const list = data ? data.dashboardsBySite.map((db) => {
        return (
            <List.Item key={db.uid}>
                <List.Icon name='chart bar' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header as={Link} to={`/sites/${siteName}/dashboard/${db.uid}`}>{db.title}</List.Header>
                    <List.Description as={Link} to={`/sites/${siteName}/dashboard/${db.uid}`}>{db.uid}</List.Description>
                </List.Content>
            </List.Item>
        );
    }) : [];

    // this is for authenticating to grafana
    //const hiddenIframeForAuth = data && hasPanel(data.dashboardsBySite) ? (
        // @ts-ignore
    //    <IFrameLoader id={0} url={"http://135.181.157.235"} />
    //) : null;

    const iframeStyle = {
        display: 'none'
    };
    const hiddenIframeForAuth =  //<IFrameLoader id={0} url={"https://grafana.lca-dashboard.simupedia.com"} />;
    <iframe title={"for auth"} loading="lazy" src={"https://grafana.lca-dashboard.simupedia.com"} width="100%" height={1} frameBorder="0" marginHeight={0} marginWidth={0} style={iframeStyle}></iframe>;

    const content = (
        <>
            <List divided relaxed inverted>
                {list}
            </List>
            {hiddenIframeForAuth}
        </>
    );

    return (
        <div>
            {content}
        </div>
    );
};

export default observer(Dashboards);
