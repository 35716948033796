import { observer } from 'mobx-react';
import React from 'react';
import ReactMarkdown from 'react-markdown'
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Grid, Icon, Placeholder } from 'semantic-ui-react';
import svgPanZoom from 'svg-pan-zoom';
import { RootStoreType, useQuery } from '../models';
import { ModelModelType } from '../models/ModelModel';

interface Params {
    siteName: string;
    modelName: string;
}

interface MyModelsProps extends RouteComponentProps<Params> {
    active: boolean;
}

const resultSelector = `name
models{
  name
  flowsheet
  description
}`;

const MyModels = ({ active, match, history }: MyModelsProps) => {

    const { siteName, modelName } = match.params;

    const { store, error, loading, data } = useQuery((store: RootStoreType) =>
        store.querySites({}, resultSelector)
    );

    const site = data ? data.sites.filter((sitee) => sitee.name === siteName) : [];
    const models: ModelModelType[] = site.length > 0 && site[0].models ? site[0].models : [];
    const model: ModelModelType[] = models.filter((model) => model.name === modelName);
    const modelSVG = model.length > 0 ? model[0].flowsheet : undefined;
    const description = model.length > 0 && model[0].description ? model[0].description : undefined;

    const onRefChange = (svgElement: HTMLDivElement) => {
        console.log('on load');
        if (svgElement) {
            const svg = svgElement.children.length > 0 ? svgElement.children[0] : null;
            if (svg) {
                console.log('svg.current is not null ' + svg);
                const instance = svgPanZoom(svg as SVGElement, {
                    zoomEnabled: true,
                    controlIconsEnabled: true,
                    minZoom: 0.1,
                    maxZoom: 20,
                });
                instance.fit();
                instance.center();
                //instance.zoom(0.4);
                //instance.pan({ x: 0, y: 0 });
            } else {
                console.log('svg.current is null');
            }
        }
    };

    const height = 600;
    const svgg = modelSVG ? (
        <div style={{ width: '100%', height: `${height}px`, border: '1px solid #d4d4d5', backgroundColor: 'white' }} ref={onRefChange} dangerouslySetInnerHTML={{ __html: modelSVG }} />
    ) : (
        <Placeholder inverted fluid >
            <Placeholder.Image style={{height: `${height}px`}} />
        </Placeholder>
    );

    const backToDashboard = () => history.goBack();

    const descriptionBlock = description ? (
        <ReactMarkdown>
            {description}
        </ReactMarkdown>
    ) : (
        <Placeholder inverted fluid >
            <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    );

    return (
        <Grid stackable>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Button inverted icon={true} onClick={backToDashboard}>
                        <Icon name="chevron left" />
                        Back to models
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    {svgg}
                </Grid.Column>
                <Grid.Column>
                    {descriptionBlock}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default withRouter(observer(MyModels));