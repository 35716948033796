import { UserManagerSettings } from "oidc-client"

const {__OIDC_AUTHORITY_URI__, __OIDC_CLIENT_ID__} = window as any;

export const authority = process.env.REACT_APP_OIDC_AUTHORITY_URI ? process.env.REACT_APP_OIDC_AUTHORITY_URI : __OIDC_AUTHORITY_URI__;
export const client_id = process.env.REACT_APP_OIDC_CLIENT_ID ? process.env.REACT_APP_OIDC_CLIENT_ID : __OIDC_CLIENT_ID__;

const { protocol, hostname, port } = window.location;

const IDENTITY_CONFIG: UserManagerSettings = {
    authority, //(string): The URL of the OIDC provider.
    client_id, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}/callback`, //The URI of your client application to receive a response from the OIDC provider.
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}/silentrenew`, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: `${protocol}//${hostname}${port ? `:${port}` : ''}/logoutcallback`, // (string): The OIDC post-logout redirect URI.
    response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    scope: "openid profile email roles", //(string, default: 'openid'): The scope being requested from the OIDC provider.
};

export default IDENTITY_CONFIG;
