import { observer } from 'mobx-react';
import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Header, Tab, TabProps } from 'semantic-ui-react';
import Dashboards from '../components/Dashboards';
import Model from '../components/Model';
import MyModels from '../components/MyModels';
import DefaultLayout from '../layouts/DefaultLayout';
import dashboard from './dashboard';

interface Params {
    name: string;
}

interface SiteProps extends RouteComponentProps<Params> {

}

const Site = ({ match, location, history }: SiteProps) => {
    const { name } = match.params;

    const dashboardPane = (
        <Switch>
            <Route path={`/sites/:siteName/dashboard/:uid`} component={dashboard} />
            <Route path="/sites/:siteName" render={(props) => <Dashboards {...props} siteName={name} />} />
        </Switch>
    );

    const activeIndex = location.pathname.indexOf(`/sites/${name}/models`) > -1 ? 1 : 0;

    const modelPane = (
        <Switch>
            <Route path="/sites/:siteName/models/:modelName" render={() => <Model active={activeIndex === 1} />} />
            <Route path="/sites/:siteName/models" render={() => <MyModels siteName={name} active={activeIndex === 1} />} />
        </Switch>
    );

    const panes = [
        { key: 'dashboards', menuItem: { key: 'dashboards', icon: 'chart bar', content: 'Dashboards' }, pane: { content: dashboardPane, className: 'inverted' }},
        { key: 'models', menuItem: { key: 'models', icon: 'box', content: 'Models' }, pane: {content: modelPane, className: 'inverted' }},
    ];

    const onTabChange = (e: any, data: TabProps) => {
        if (data.activeIndex === 1) {
            history.push(`/sites/${name}/models`);
        } else {
            history.push(`/sites/${name}`);
        }
    }

    return (
        <DefaultLayout>
            <Header inverted>{name}</Header>
            <div className='site'>
                <Tab activeIndex={activeIndex} renderActiveOnly={false} onTabChange={onTabChange} menu={{ inverted: true }} panes={panes} />
            </div>
        </DefaultLayout>
    )
};

export default withRouter(observer(Site));