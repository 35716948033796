import React, { useEffect } from 'react';
import { observer } from "mobx-react"
import { useInject } from '../utils/useStore';

const Callback = () => {
    const signinRedirectCallback = useInject((store) => store.auth.signinRedirectCallback)
    useEffect(() => {
        signinRedirectCallback();
    });

    return (
        <div>loading...</div>
    )
}

export default observer(Callback);
